// import Viewer from "v-viewer/src/component.vue"
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import Vue from 'vue';
Vue.use(Viewer);
export default {
    // components: {
    //     Viewer,
    // },
    data () {
        return {
            viewOptions: {
                zIndex: 999999999,
                inline: false,
                button: true,
                navbar: false,
                title: false,
                toolbar: {
                    zoomIn: 4,
                    zoomOut: 4,
                    oneToOne: 4,
                    reset: 4,
                    prev: 0,
                    play: {
                        show: 0,
                        size: 'large',
                    },
                    next: 0,
                    rotateLeft: 4,
                    rotateRight: 4,
                    flipHorizontal: 4,
                    flipVertical: 4,
                },
                tooltip: true,
                movable: true,
                zoomable: true,
                rotatable: true,
                scalable: false,
                transition: true,
                fullscreen: false,
                keyboard: true,
            },
        }
    }
}
